@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';
@import './Icons.scss';
$font-stack: Helvetica, sans-serif;
$primary-color: #333;
body {
	font: 100%;
	font-family: 'Roboto' !important;
	height: 100vh;
}

.app-content {
	margin-left: 3.6em;
}

.app-content-details {
	display: flex;
}

/* Bootstrap overrides*/
.dropdown-toggle::after {
	float: right;
	margin-top: 3%;
}

.dropdown-toggle {
	width: 100%;
	text-align: left !important;
	background-color: #fff !important;
	border-color: #fff !important;
	color: #000 !important;
	border-radius: 0 !important;
}

.dropdown-menu {
	width: 100%;
	max-height: 197px ​ !important;
	overflow-y: scroll !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
	cursor: not-allowed !important;
}

.form-control,
.btn {
	border-radius: 3px !important;
}
.form-control {
	font-weight: 500 !important;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: none !important;
}

/* Bootstrap overrides*/

.error-message {
	color: red;
	font-weight: bolder;
}

.app-header {
	height: 100px;
	border-bottom: 2px solid #efefef;
	width: 100%;
	position: fixed;
	z-index: 1;
	background-color: #fff;
	margin-left: 60px;
	display: flex;
}

.user-info {
	font-size: 15px;
}

.app-header-user {
	float: right;
	padding-top: 30px;
	padding-right: 165px;
}

.font-size-11 {
	font-size: 11px;
}

.app-logo {
	background-repeat: no-repeat;
	width: 64px;
	height: 64px;
	float: left;

	margin-top: 20px;
	cursor: pointer;
}

.opportunity-search-btn {
	font-family: 'Roboto';
	font-size: 16px !important;
	font-weight: bold !important;
	color: #fff !important;
	background-color: #ef2312 !important;
}

.modal-content {
	border-radius: 3px !important;
	border-top: 4px solid #e42312 !important;
}

.modal-header {
	font-size: 24px !important;
	font-weight: bold !important;
}
.btn-color {
	background-color: #e42312 !important;
	color: white !important;
}

// New Responsive

.app-sidebar {
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
}

.flex {
	display: flex;
}

.header {
	border-bottom: 2px solid #efefef;
}
.width-50 {
	display: inline-block;
	width: 89%;
	padding-top: 27px;
	padding-left: 12px;
}

.text-align-right {
	text-align: right;
}

.large-screen-width {
	max-width: 90% !important;
}

.search-button {
	font-size: 16px;
}

input,
.dropdown {
	font-size: 15px !important;
	button {
		font-size: 15px !important;
	}
}
@include media-breakpoint-down(lg) {
	// Styles
	input,
	.dropdown {
		font-size: 12px !important;
		button {
			font-size: 12px !important;
		}
	}
	.app-sidebar {
		display: none;
	}
	.app-content {
		margin-left: 0;
	}
	.large-screen-width {
		max-width: 100% !important;
	}
	.user-info {
		font-size: 13px;
	}
	.search-button {
		font-size: 15px;
	}
	.btn-enabled {
		font-size: 14px !important;
	}
	.btn-disabled {
		font-size: 14px !important;
	}
}

.table td {
	word-break: break-word;
}
textarea {
	resize: none;
	width: 100%;
}
.dropdown .dropdown-toggle::after {
	position: absolute;
	right: 5px;
}

.dropdown button {
	position: relative;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.bold-font {
	font-weight: bold !important;
}
.btn.disabled,
.btn:disabled {
	cursor: not-allowed;
}
.btn-enabled {
	font-family: 'Roboto';
	font-size: 16px;
	font-weight: bold !important;
	color: #fff !important;
	background-color: #ef2312 !important;
}
input:disabled {
	cursor: not-allowed;
}
.btn-disabled {
	font-family: 'Roboto';
	font-size: 16px !important;
	font-weight: bold !important;
	background-color: #c3c3c3 !important;
	color: #fff !important;
	cursor: not-allowed !important;
}
.full-width {
	width: 100% !important;
}
.modal-xl {
	width: 90% !important;
	max-width: 90% !important;
	height: 100% !important;
}
.modal-lg {
	width: 60% !important;
	max-width: 60% !important;
}
.invalid-style {
	border: 1px solid #e42312 !important;
	border-radius: 3px !important;
	border-left: 5px solid #e42313 !important;
}
.rbt-invalid-style {
	input {
		border: 1px solid #e42312 !important;
		border-radius: 3px !important;
		border-left: 5px solid #e42313 !important;
	}
}
.fixed-bottom {
	width: 100%;
	height: 100%;
	z-index: 9999 !important;
	background-color: rgba(192, 192, 192, 0.5);
	div:first-child {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
