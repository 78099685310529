.sidebar {
	width: 60px;
	background-color: black;
	height: 100%;
}

.sidebar-button {
	background-color: #e42312;
	border-radius: 100%;
	width: 40px;
	height: 40px;
	margin: 0 0 0 10px;
	cursor: pointer;
}

.sidebar-button-plus-icon {
	width: 100%;
	color: #fff;
	text-align: left;
	line-height: 36px;
	padding-left: 11px;
	font-size: 30px;
}

.settings-icon {
	background-color: #e42312;
	border-radius: 100%;
	width: 40px;
	height: 40px;
	margin: 0 0 0 -8px;
	cursor: pointer;
}

.email-configuration-icon
{
	background-size: 42px !important;
	width:70px;
	height: 50px;
	padding: 15px;	
	background-repeat: no-repeat !important;
	background-position: center;
	float: right;
	margin: 0 0 0 15px;
	cursor: pointer;
	color: #fff;
	background-color:black;
	
}

.terms-and-conditions-icon
{
	background-size: 42px !important;
	width:70px;
	height: 50px;
	padding: 15px;	
	background-repeat: no-repeat !important;
	background-position: center;
	float: right;
	cursor: pointer;	
}

.App {
	font-family: sans-serif;
	text-align: center;
  }
  
  body {
	font-family: Arial, Helvetica, sans-serif;
	margin: 0;
  }
  
  .navbar {
	overflow: hidden;
	background-color: #333; 
  }
  
  .navbar a {
	float: right;
	font-size: 16px;
	color: white;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
  }
  
  .subnav {
	float:left;
	overflow: hidden;
  }
  
  .subnav .subnavbtn {
	font-size: 16px;  
	border: none;
	outline: none;
	color: white;
	padding: 14px 16px;
	background-color: inherit;
	font-family: inherit;
	margin: 0;
  }

  .subnav-content {
	display:none;
	position:relative;
	right: 50;
	width: 100%;
	z-index: 1;
  }
  
  .subnav-content {
	float:inline-end;
	color: black;
	text-decoration:dashed;
	padding-left: 55%;
	padding-top: 0%;
	//right: 90;
	font-size: large;
	font-weight:bold;
	height: 50px;
	//background-color: #e42312;
  }
  
  .subnav:hover .subnav-content {
	display:grid;
	position:absolute;
  }
  