@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';
@import '../../assets/scss/Icons.scss';

.div-table-database {
	padding: 0px 20px 0px 20px;
	background-color: white;
	width: 70%;
	border: 1px solid #dee2e6;
	position: fixed;
}
.role-kebab-dropdown{
	.selected-role{
		color: #e42312 !important;
	}
	.dropdown-item{
		padding: 5px;
		background-color: #fff;
		font-weight: normal !important;
	}
	.dropdown-menu {
		background-color: #fff;
		min-width: auto !important;
	}
	.dropdown-item:focus,
	.dropdown-item:hover {
		background-color: #fff;
		font-weight: bold !important;

		.kebab-option-desc {
			color: #000;
		}
	}
}
.dashboard-search {
	display: inline-block;

	.rbt-input {
		width: 100% !important;
	}
}

.padding-organsiation {
	padding: 3px 5px 3px 5px;
	border: 1px solid #ced4da;
}

#async-example {
	display: none;
}

.user {
	display: inline-block;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	object-fit: cover;
}
.text-profile {
	width: 65%;
	margin-top: 0.5rem !important;
	text-align: end;
}
.text-profile-only {
	width: 100%;
	margin-top: 0.5rem !important;
	text-align: end;
}

.button-add-quotation {
	width: 35%;
	button {
		float: right;
	}
}

@include media-breakpoint-down(lg) {
	// Styles
	.text-profile {
		width: 60%;
		margin-top: 0 !important;
	}

	.button-add-quotation {
		width: 40%;
	}
}

.overlay-language {
	position: fixed;
	bottom: 0;
	right: 0;
	background-color: #cccccc;
	padding: 10px;
	z-index: 9999;
}
