@import './iconStorageUrls.scss';
.devClass {
	.app-logo {
		background-image: url(#{$azure-image-storage-base-url-dev}theuma-logo.svg);
	}
	.dashboard-search {
		.rbt-input {
			background-image: url(#{$azure-image-storage-base-url-dev}icon-search.svg);
		}
	}
	.rbt-input {
		background-image: url(#{$azure-image-storage-base-url-dev}icon-search.svg);
	}
	.filter-icon {
		background: url('../../assets/images/filter.png') no-repeat;
	}
	.search-clear-icon {
		background: url(#{$azure-image-storage-base-url-dev}icon-cancel.png);
	}
	.copy-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}icon-duplicate.svg);
	}

	.lock-icon-grid {
		background: url(#{$azure-image-storage-base-url-dev}Lock.svg);
	}
	.email-configuration-icon
	{
		background: url(#{$azure-image-storage-base-url-dev}email.png);
	}
}
.testClass {
	.app-logo {
		background-image: url(#{$azure-image-storage-base-url-test}theuma-logo.svg);
	}

	.dashboard-search {
		.rbt-input {
			background-image: url(#{$azure-image-storage-base-url-test}icon-search.svg);
		}
	}
	.rbt-input {
		background-image: url(#{$azure-image-storage-base-url-test}icon-search.svg);
	}
	.filter-icon {
		background: url('../../assets/images/filter.png') no-repeat;
	}
	.search-clear-icon {
		background: url(#{$azure-image-storage-base-url-test}icon-cancel.png);
	}
	.copy-icon {
		background-image: url(#{$azure-image-storage-base-url-test}icon-duplicate.svg);
	}
	.lock-icon-grid {
		background: url(#{$azure-image-storage-base-url-test}Lock.svg);
	}
	.email-configuration-icon
	{
		background: url(#{$azure-image-storage-base-url-test}email.png);
	}
}

.prodClass {
	.app-logo {
		background-image: url(#{$azure-image-storage-base-url-prod}theuma-logo.svg);
	}

	.dashboard-search {
		.rbt-input {
			background-image: url(#{$azure-image-storage-base-url-prod}icon-search.svg);
		}
	}
	.rbt-input {
		background-image: url(#{$azure-image-storage-base-url-prod}icon-search.svg);
	}
	.filter-icon {
		background: url('../../assets/images/filter.png') no-repeat;
	}
	.search-clear-icon {
		background: url(#{$azure-image-storage-base-url-prod}icon-cancel.png);
	}
	.copy-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}icon-duplicate.svg);
	}
	.lock-icon-grid {
		background: url(#{$azure-image-storage-base-url-prod}Lock.svg);
	}
	.email-configuration-icon
	{
		background: url(#{$azure-image-storage-base-url-prod}email.png);
	}
}
