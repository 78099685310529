@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';
$azure-image-storage-base-url: 'https://sttheumaqtapi.blob.core.windows.net/qt-static-images/';
.quotation-panel {
	background: #f0f0f0;
	padding: 20px;
}
.quotation-panel-white {
	background: white;
	padding: 20px;
	border: 1px solid #cbcbcb;
}
.background-quotation {
	background: #f0f0f0;
	margin-bottom: 0px !important;
}

.quotation-button {
	width: 100%;
	font-size: 16px;
	text-align: end;
}
.project-address-input-section {
	display: flex;
}
.create-quotation-title {
	font-family: 'Roboto';
	font-size: 30px;
}
.red-color {
	border-color: red !important;
}
.opportunity-sub-title {
	font-size: 18px;
	font-weight: bold;
}
.textbox-font {
	font-size: 15px;
	color: #000000;
	font-weight: 500;
}
.bold-header-font {
	color: #000;
	opacity: 0.6;
	font-weight: bold;
	font-size: 11px;
	padding-bottom: 5px;
}
.bold-header-font-margin {
	margin-left: 0px !important;
	margin-right: 0px !important;
}
.contact-person-table {
	background-color: #fff;
	thead th {
		border-bottom: 0px !important;
	}
}
.div-background {
	padding: 0px 10px 0px 10px;
	background: white;
}
.tbody-parent {
	content: '';
	height: 20px;
}
.quotation-button-enabled {
	font-family: 'Roboto';
	font-size: 16px !important;
	font-weight: bold !important;
	color: #fff !important;
	background-color: #ef2312 !important;
	padding: 10px 32px 10px 32px !important;
}

.dropdown button {
	font-weight: 500 !important;
}
.rbt-input {
	width: 40% !important;
	background-repeat: no-repeat;
	background-position: 96% 11px;
	background-size: 23px 23px !important;
	height: calc(1.5em + 0.75rem + 11px) !important;
}
.font-table {
	font-weight: 500;
	font-size: 15px;
	color: #000000;
}
.quotation-button-disabled {
	background-color: #c3c3c3 !important;
	color: #fff !important;
}
.table-content {
	font-size: 14px;
}
.validation-message {
	font-size: 14px !important;
	color: #e42312;
	font-family: Roboto;
}
.selected-row {
	background-color: #3e648b;
}
@include media-breakpoint-down(lg) {
	// Styles
	.opportunity-sub-title {
		font-size: 16px !important;
	}
	.create-quotation-title {
		font-size: 18px !important;
	}
	.bold-header-font {
		font-size: 9px !important;
	}
	.textbox-font {
		font-size: 12px !important;
	}
	.table-content {
		font-size: 12px !important;
	}
	.font-table {
		font-size: 12px !important;
	}
	.quotation-button {
		font-size: 14px !important;
	}
	.validation-message {
		font-size: 9px !important;
	}
}
.rdp-input-group {
	.rdp-form-control {
		height: auto !important;
	}
}
