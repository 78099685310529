@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';
@import '../../assets/scss/Icons.scss';
.opportunity-search-panel {
	padding: 20px;
	background-color: #f0f0f0;
	height: 145px;
}
.dropdownTable {
	margin-bottom: 1rem;
	color: #212529;
	background-color: white;
}
.rbt-input {
	width: 40% !important;
	background-repeat: no-repeat;
	background-position: 96% 11px;
	background-size: 23px 23px !important;
	height: calc(1.5em + 0.75rem + 11px) !important;
}
.quotation-row {
	cursor: pointer;

	&:hover {
		background-color: rgb(62, 100, 139) !important;
	}
}
.td-bold {
	font-size: 15px;
	font-weight: 500;
	color: #000000;
}
.td-light {
	font-size: 15px;
	font-weight: 400;
	color: #000000;
}

.opportunity-search-title {
	font-family: 'Roboto';
	font-weight: bold;
	font-size: 18px;
	padding-bottom: 10px;
}

.opportunity-search-table-header {
	margin-left: 20px;
	font-size: 11px;
	font-weight: bold;
	color: #a0a0a0;
}
.div-table {
	padding: 0px 20px 0px 20px;
	background-color: white;
	width: 80%;
	border: 1px solid #dee2e6;
	max-height: 300px;
	overflow-x: hidden;
	overflow-y: scroll;
}
.opportunity-search-table-header th {
	color: #000000;
}
.create-quotation-title {
	font-family: 'Roboto';
	font-size: 30px;
}
.dropdown-menu {
	width: auto !important;
}
.search-loading {
	width: 100%;
	text-align: center;
}
@include media-breakpoint-down(lg) {
	// Styles
	.div-table {
		width: 90%;
	}
	.opportunity-search-title {
		font-size: 16px !important;
	}
	.opportunity-search-table-header {
		font-size: 9px;
	}
}
