@import '../../assets/scss/iconStorageUrls.scss';
.devClass {
	.collapse-lines-icon{
		background: url(#{$azure-image-storage-base-url-dev}icon-collapse-lines.png);
	}
	.edit-icon {
		background: url(#{$azure-image-storage-base-url-dev}icon-editversion.svg) no-repeat;
	}
	.copy-url-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}icon-duplicate.svg);
	}
	.notes-icon-ql {
		background-image: url(#{$azure-image-storage-base-url-dev}Notes-blue.svg);
	}
	.search-clear-icon {
		background: url(#{$azure-image-storage-base-url-dev}icon-cancel.png);
	}
	.lock-icon {
		background: url(#{$azure-image-storage-base-url-dev}Lock.svg);
	}
	.lock-icon-grid {
		background: url(#{$azure-image-storage-base-url-dev}Lock.svg);
	}
	.edit-icon-discount {
		background: url(#{$azure-image-storage-base-url-dev}edit-discount-icon.png) no-repeat;
	}
	.edit-icon-description {
		background: url(#{$azure-image-storage-base-url-dev}icon-editversion.svg) no-repeat;
	}
	.edit-icon-discount-second {
		background: url(#{$azure-image-storage-base-url-dev}edit-discount-icon.png) no-repeat;
	}
	.ok-icon-discount {
		background: url(#{$azure-image-storage-base-url-dev}ok-icon.png) no-repeat;
	}
	.icon-add-search-filter {
		background: url(#{$azure-image-storage-base-url-dev}icon-confirm.png);
	}
	.ok-btn {
		background: url(#{$azure-image-storage-base-url-dev}ok-icon.png) no-repeat;
	}
	.ok-icon-discount-second {
		background: url(#{$azure-image-storage-base-url-dev}ok-icon.png) no-repeat;
	}
	.cancel-icon-discount {
		background: url(#{$azure-image-storage-base-url-dev}cancel-icon.png) no-repeat;
	}
	.cancel-btn {
		background: url(#{$azure-image-storage-base-url-dev}cancel-icon.png) no-repeat;
	}
	.cancel-icon-discount-second {
		background: url(#{$azure-image-storage-base-url-dev}cancel-icon.png) no-repeat;
	}
	.icon-add-search-filter {
		background: url(#{$azure-image-storage-base-url-dev}icon-confirm.png);
	}
	.rbt-input {
		background-image: url(#{$azure-image-storage-base-url-dev}icon-search.svg);
	}
	.move-up {
		background-image: url(#{$azure-image-storage-base-url-dev}icon-configure.svg);
	}
	.move-down {
		background-image: url(#{$azure-image-storage-base-url-dev}icon-configure.svg);
	}
	.benor-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}benor-icon.png);
	}
	.doorWidth-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}doorWidth.png);
	}
	.doorHeight-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}doorHeight.png);
	}
	.doorThickness-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}Theuma-icoon-deurdikte.png);
	}
	.jambHeight-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}jambHeight.png);
	}
	.wallThickness-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}wallThickness.png);
	}
	.benor-icon-1713 {
		background-image: url(#{$azure-image-storage-base-url-dev}Benor-1713.png);
	}
	.benor-icon-2274 {
		background-image: url(#{$azure-image-storage-base-url-dev}Benor-2274.png);
	}
	.benor-icon-2287 {
		background-image: url(#{$azure-image-storage-base-url-dev}Benor-2287.png);
	}
	.benor-icon-3034 {
		background-image: url(#{$azure-image-storage-base-url-dev}Benor-3034.png);
	}
	.fireResistance-icon-30 {
		background-image: url(#{$azure-image-storage-base-url-dev}FireResistance30.png);
	}
	.fireResistance-icon-60 {
		background-image: url(#{$azure-image-storage-base-url-dev}FireResistance60.png);
	}
	.fireResistance-icon-ei130 {
		background-image: url(#{$azure-image-storage-base-url-dev}FireResistanceEI130.png);
	}
	.acousticResistance-icon-29dB {
		background-image: url(#{$azure-image-storage-base-url-dev}AcousticResistance-29.png);
	}
	.acousticResistance-icon-30dB {
		background-image: url(#{$azure-image-storage-base-url-dev}AcousticResistance-30.png);
	}
	.acousticResistance-icon-31dB {
		background-image: url(#{$azure-image-storage-base-url-dev}AcousticResistance-31.png);
	}
	.acousticResistance-icon-37dB {
		background-image: url(#{$azure-image-storage-base-url-dev}AcousticResistance-37.png);
	}
	.acousticResistance-icon-39dB {
		background-image: url(#{$azure-image-storage-base-url-dev}AcousticResistance-39.png);
	}
	.acousticResistance-icon-41dB {
		background-image: url(#{$azure-image-storage-base-url-dev}AcousticResistance-41.png);
	}
	.acousticResistance-icon-42dB {
		background-image: url(#{$azure-image-storage-base-url-dev}AcousticResistance-42.png);
	}
	.acousticResistance-icon-46dB {
		background-image: url(#{$azure-image-storage-base-url-dev}AcousticResistance-46.png);
	}
	.burglarResistance-icon-WK2 {
		background-image: url(#{$azure-image-storage-base-url-dev}BurglarResistance-WK2.png);
	}
	.burglarResistance-icon-WK3 {
		background-image: url(#{$azure-image-storage-base-url-dev}BurglarResistance-WK3.png);
	}
	.smokeResistance-icon-sas200 {
		background-image: url(#{$azure-image-storage-base-url-dev}SmokeResistanceSAS200.jpg);
	}
	.hygrothermalResistance-icon-IIb {
		background-image: url(#{$azure-image-storage-base-url-dev}HygrothermalResistance-IIb.png);
	}
	.hygrothermalResistance-icon-IIc {
		background-image: url(#{$azure-image-storage-base-url-dev}HygrothermalResistance-IIc.png);
	}
	.llc-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}llc-icon.png);
	}
	.doorWidthPassive-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}Theuma-icoon-deurbreedte-dubbel.png);
	}

	.move-down-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}actieve-pijl.svg);
	}
	.move-up-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}actieve-pijl.svg);
	}
	.kebab-menu {
		background-image: url(#{$azure-image-storage-base-url-dev}kebab-menu.png);
	}
	.add-quotation-line-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}icon-add-configuration.png);
	}
	.user-traces-approval-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}approval-icon.png);
	}
	.add-individual-artical-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}icon-add-product.png);
	}
	.share-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}Share.svg);
	}

	.unlock-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}UnLock.svg);
	}
	.export-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}icon-export.png);
	}
	.download-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}download-icon.png);
	}
	.pdfjson-icon
	{
		background-image: url(#{$azure-image-storage-base-url-dev}pdf.png);
	}
	.download-without-line-icon{
		background-image: url(#{$azure-image-storage-base-url-dev}download-icon.png);
	}
	.search-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}icon-filter.png);
	}
	.search-icon-active {
		background-image: url(#{$azure-image-storage-base-url-dev}icon-filter-active.png);
	}
	.edit-icon {
		background: url(#{$azure-image-storage-base-url-dev}icon-editversion.svg) no-repeat;
	}
	.settings-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}icon-configure.svg);
	}
	.terms-and-conditions-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}terms-and-conditions-icon.png);
	}
	.delete-icon {
		background-image: url(#{$azure-image-storage-base-url-dev}icon-remove.svg);
	}
	.warning-red-icon {
		background: url(#{$azure-image-storage-base-url-dev}icon-error.png) no-repeat;
	}
	.history-icon {
		background: url(#{$azure-image-storage-base-url-dev}history.svg) no-repeat;
	}
	.discount-approval-icon
	{
		background: url(#{$azure-image-storage-base-url-dev}discountApproval.svg) no-repeat;
	}
	.ok-btn {
		background: url(#{$azure-image-storage-base-url-dev}icon-confirm.png) no-repeat;
	}
	.dropdown-item:focus,
	.dropdown-item:hover {
		.configure-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}icon-configure-active.png);
		}
		.edit-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}Edit-active.svg);
		}
		.delete-icon-kebab 
		{
			background-image: url(#{$azure-image-storage-base-url-dev}icon-delete-active.png);
		}
		.discount-icon-kebab 
		{
			background-image: url(#{$azure-image-storage-base-url-dev}discount-active.svg);
		}
		.discount-history-icon-kebab
		{
			background-image: url(#{$azure-image-storage-base-url-dev}history-kebab.png);
		}
		.euro-icon-kebab{
			background-image: url(#{$azure-image-storage-base-url-dev}euro-active.svg);
		}
		.quotationline-notes-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}Notes-active.svg);
		}
		.attributes-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}Attributes-active.svg);
		}
		.alternative-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}icon-alternative-active.png);
		}
		.copy-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}icon-duplicate-active.png);
		}
		.activate-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}Activate-active.svg);
		}
	}
	.kebab-menu-dropdown {
		.configure-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}icon-configure.png);
		}
		.edit-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}Edit.svg);
		}
		.delete-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}icon-delete.png);
		}
		.discount-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}discount.svg);
		}
		.discount-history-icon-kebab 
		{
			background-image: url(#{$azure-image-storage-base-url-dev}history-kebab.png);
		}
		.euro-icon-kebab{
			background-image: url(#{$azure-image-storage-base-url-dev}euro.svg);
		}
		.quotationline-notes-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}Notes.svg);
		}
		.attributes-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}Attributes.svg);
		}
		.alternative-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}icon-alternative.png);
		}
		.copy-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}icon-duplicate.png);
		}
		.activate-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}Activate.svg);
		}
	}
	.edit-icon-kebab {
		background-image: url(#{$azure-image-storage-base-url-dev}Edit.svg);
	}
	.notes-icon-kebab {
		background-image: url(#{$azure-image-storage-base-url-dev}Notes.svg);
	}
	.notes-icon-kebab-filled {
		background-image: url(#{$azure-image-storage-base-url-dev}Notes-color.svg);
	}
	.internal-notes-icon-kebab {
		background-image: url(#{$azure-image-storage-base-url-dev}Internal-Notes.svg);
	}
	.internal-notes-icon-kebab-filled {
		background-image: url(#{$azure-image-storage-base-url-dev}Internal-Notes-color.svg);
	}
	.status-icon-kebab {
		background-image: url(#{$azure-image-storage-base-url-dev}status.svg);
	}
	.kebab-header-dropdown {
		.dropdown-item:focus,
		.dropdown-item:hover {
			.edit-icon-kebab {
				background-image: url(#{$azure-image-storage-base-url-dev}Edit-active.svg);
			}
			.notes-icon-kebab {
				background-image: url(#{$azure-image-storage-base-url-dev}Notes-active.svg);
			}
			.notes-icon-kebab-filled {
				background-image: url(#{$azure-image-storage-base-url-dev}Notes-color-active.svg);
			}
			.internal-notes-icon-kebab {
				background-image: url(#{$azure-image-storage-base-url-dev}Internal-Notes-active.svg);
			}
			.internal-notes-icon-kebab-filled {
				background-image: url(#{$azure-image-storage-base-url-dev}Internal-Notes-color-active.svg);
			}
			.status-icon-kebab {
				background-image: url(#{$azure-image-storage-base-url-dev}status-active.svg);
			}
		}
	}
}
.testClass {
	.collapse-lines-icon{
		background: url(#{$azure-image-storage-base-url-test}icon-collapse-lines.png);
	}
	.edit-icon {
		background: url(#{$azure-image-storage-base-url-test}icon-editversion.svg) no-repeat;
	}
	.copy-url-icon {
		background-image: url(#{$azure-image-storage-base-url-test}icon-duplicate.svg);
	}
	.notes-icon-ql {
		background-image: url(#{$azure-image-storage-base-url-test}Notes-blue.svg);
	}
	.search-clear-icon {
		background: url(#{$azure-image-storage-base-url-test}icon-cancel.png);
	}
	.lock-icon {
		background: url(#{$azure-image-storage-base-url-test}Lock.svg);
	}
	.edit-icon-discount {
		background: url(#{$azure-image-storage-base-url-test}edit-discount-icon.png) no-repeat;
	}
	.edit-icon-description {
		background: url(#{$azure-image-storage-base-url-test}icon-editversion.svg) no-repeat;
	}
	.edit-icon-discount-second {
		background: url(#{$azure-image-storage-base-url-test}edit-discount-icon.png) no-repeat;
	}
	.ok-icon-discount {
		background: url(#{$azure-image-storage-base-url-test}ok-icon.png) no-repeat;
	}
	.icon-add-search-filter {
		background: url(#{$azure-image-storage-base-url-test}icon-confirm.png);
	}
	.ok-btn {
		background: url(#{$azure-image-storage-base-url-test}ok-icon.png) no-repeat;
	}
	.ok-icon-discount-second {
		background: url(#{$azure-image-storage-base-url-test}ok-icon.png) no-repeat;
	}
	.cancel-icon-discount {
		background: url(#{$azure-image-storage-base-url-test}cancel-icon.png) no-repeat;
	}
	.cancel-btn {
		background: url(#{$azure-image-storage-base-url-test}cancel-icon.png) no-repeat;
	}
	.cancel-icon-discount-second {
		background: url(#{$azure-image-storage-base-url-test}cancel-icon.png) no-repeat;
	}
	.icon-add-search-filter {
		background: url(#{$azure-image-storage-base-url-test}icon-confirm.png);
	}
	.rbt-input {
		background-image: url(#{$azure-image-storage-base-url-test}icon-search.svg);
	}
	.move-up {
		background-image: url(#{$azure-image-storage-base-url-test}icon-configure.svg);
	}
	.move-down {
		background-image: url(#{$azure-image-storage-base-url-test}icon-configure.svg);
	}
	.benor-icon {
		background-image: url(#{$azure-image-storage-base-url-test}benor-icon.png);
	}
	.doorWidth-icon {
		background-image: url(#{$azure-image-storage-base-url-test}doorWidth.png);
	}
	.doorHeight-icon {
		background-image: url(#{$azure-image-storage-base-url-test}doorHeight.png);
	}
	.doorThickness-icon {
		background-image: url(#{$azure-image-storage-base-url-test}Theuma-icoon-deurdikte.png);
	}
	.jambHeight-icon {
		background-image: url(#{$azure-image-storage-base-url-test}jambHeight.png);
	}
	.wallThickness-icon {
		background-image: url(#{$azure-image-storage-base-url-test}wallThickness.png);
	}
	.benor-icon-1713 {
		background-image: url(#{$azure-image-storage-base-url-test}Benor-1713.png);
	}
	.benor-icon-2274 {
		background-image: url(#{$azure-image-storage-base-url-test}Benor-2274.png);
	}
	.benor-icon-2287 {
		background-image: url(#{$azure-image-storage-base-url-test}Benor-2287.png);
	}
	.benor-icon-3034 {
		background-image: url(#{$azure-image-storage-base-url-test}Benor-3034.png);
	}
	.fireResistance-icon-30 {
		background-image: url(#{$azure-image-storage-base-url-test}FireResistance30.png);
	}
	.fireResistance-icon-60 {
		background-image: url(#{$azure-image-storage-base-url-test}FireResistance60.png);
	}
	.fireResistance-icon-ei130 {
		background-image: url(#{$azure-image-storage-base-url-test}FireResistanceEI130.png);
	}
	.acousticResistance-icon-29dB {
		background-image: url(#{$azure-image-storage-base-url-test}AcousticResistance-29.png);
	}
	.acousticResistance-icon-30dB {
		background-image: url(#{$azure-image-storage-base-url-test}AcousticResistance-30.png);
	}
	.acousticResistance-icon-31dB {
		background-image: url(#{$azure-image-storage-base-url-test}AcousticResistance-31.png);
	}
	.acousticResistance-icon-37dB {
		background-image: url(#{$azure-image-storage-base-url-test}AcousticResistance-37.png);
	}
	.acousticResistance-icon-39dB {
		background-image: url(#{$azure-image-storage-base-url-test}AcousticResistance-39.png);
	}
	.acousticResistance-icon-41dB {
		background-image: url(#{$azure-image-storage-base-url-test}AcousticResistance-41.png);
	}
	.acousticResistance-icon-42dB {
		background-image: url(#{$azure-image-storage-base-url-test}AcousticResistance-42.png);
	}
	.acousticResistance-icon-46dB {
		background-image: url(#{$azure-image-storage-base-url-test}AcousticResistance-46.png);
	}
	.burglarResistance-icon-WK2 {
		background-image: url(#{$azure-image-storage-base-url-test}BurglarResistance-WK2.png);
	}
	.burglarResistance-icon-WK3 {
		background-image: url(#{$azure-image-storage-base-url-test}BurglarResistance-WK3.png);
	}
	.smokeResistance-icon-sas200 {
		background-image: url(#{$azure-image-storage-base-url-test}SmokeResistanceSAS200.jpg);
	}
	.hygrothermalResistance-icon-IIb {
		background-image: url(#{$azure-image-storage-base-url-test}HygrothermalResistance-IIb.png);
	}
	.hygrothermalResistance-icon-IIc {
		background-image: url(#{$azure-image-storage-base-url-test}HygrothermalResistance-IIc.png);
	}
	.llc-icon {
		background-image: url(#{$azure-image-storage-base-url-test}llc-icon.png);
	}
	.doorWidthPassive-icon {
		background-image: url(#{$azure-image-storage-base-url-test}Theuma-icoon-deurbreedte-dubbel.png);
	}

	.move-down-icon {
		background-image: url(#{$azure-image-storage-base-url-test}actieve-pijl.svg);
	}
	.move-up-icon {
		background-image: url(#{$azure-image-storage-base-url-test}actieve-pijl.svg);
	}
	.kebab-menu {
		background-image: url(#{$azure-image-storage-base-url-test}kebab-menu.png);
	}
	.add-quotation-line-icon {
		background-image: url(#{$azure-image-storage-base-url-test}icon-add-configuration.png);
	}
	.user-traces-approval-icon {
		background-image: url(#{$azure-image-storage-base-url-test}approval-icon.png);
	}

	.add-individual-artical-icon {
		background-image: url(#{$azure-image-storage-base-url-test}icon-add-product.png);
	}
	.share-icon {
		background-image: url(#{$azure-image-storage-base-url-test}Share.svg);
	}

	.unlock-icon {
		background-image: url(#{$azure-image-storage-base-url-test}UnLock.svg);
	}
	.export-icon {
		background-image: url(#{$azure-image-storage-base-url-test}icon-export.png);
	}
	.download-icon {
		background-image: url(#{$azure-image-storage-base-url-test}download-icon.png);
	}
	.download-without-line-icon{
		background-image: url(#{$azure-image-storage-base-url-test}download-icon.png);
	}

	.search-icon {
		background-image: url(#{$azure-image-storage-base-url-test}icon-filter.png);
	}
	.search-icon-active {
		background-image: url(#{$azure-image-storage-base-url-test}icon-filter-active.png);
	}
	.edit-icon {
		background: url(#{$azure-image-storage-base-url-test}icon-editversion.svg) no-repeat;
	}
	.settings-icon {
		background-image: url(#{$azure-image-storage-base-url-test}icon-configure.svg);
	}
	.terms-and-conditions-icon {
		background-image: url(#{$azure-image-storage-base-url-test}terms-and-conditions-icon.png);
	}
	.delete-icon {
		background-image: url(#{$azure-image-storage-base-url-test}icon-remove.svg);
	}
	.warning-red-icon {
		background: url(#{$azure-image-storage-base-url-test}icon-error.png) no-repeat;
	}
	.history-icon {
		background: url(#{$azure-image-storage-base-url-test}history.svg) no-repeat;
	}
	.discount-approval-icon 
	{
		background: url(#{$azure-image-storage-base-url-test}discountApproval.svg) no-repeat;
	}
	.ok-btn {
		background: url(#{$azure-image-storage-base-url-test}icon-confirm.png) no-repeat;
	}
	.dropdown-item:focus,
	.dropdown-item:hover {
		.configure-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-test}icon-configure-active.png);
		}
		.edit-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}Edit-active.svg);
		}
		.delete-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-test}icon-delete-active.png);
		}
		.discount-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-test}discount-active.svg);
		}
		.discount-history-icon-kebab 
		{
			background-image: url(#{$azure-image-storage-base-url-test}history-kebab.png);
		}
		.euro-icon-kebab{
			background-image: url(#{$azure-image-storage-base-url-dev}euro-active.svg);
		}
		.quotationline-notes-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-test}Notes-active.svg);
		}
		.attributes-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}Attributes-active.svg);
		}
		.alternative-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-test}icon-alternative-active.png);
		}
		.copy-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-test}icon-duplicate-active.png);
		}
		.activate-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}Activate-active.svg);
		}
	}
	.kebab-menu-dropdown {
		.configure-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-test}icon-configure.png);
		}
		.edit-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}Edit.svg);
		}
		.delete-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-test}icon-delete.png);
		}
		.discount-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-test}discount.svg);
		}
		.discount-history-icon-kebab
		{
			background-image: url(#{$azure-image-storage-base-url-test}history-kebab.png);
		}
		.euro-icon-kebab{
			background-image: url(#{$azure-image-storage-base-url-dev}euro.svg);
		}
		.quotationline-notes-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-test}Notes.svg);
		}
		.attributes-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}Attributes.svg);
		}
		.alternative-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-test}icon-alternative.png);
		}
		.copy-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-test}icon-duplicate.png);
		}
		.activate-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}Activate.svg);
		}
	}
	.edit-icon-kebab {
		background-image: url(#{$azure-image-storage-base-url-test}Edit.svg);
	}
	.notes-icon-kebab {
		background-image: url(#{$azure-image-storage-base-url-test}Notes.svg);
	}
	.notes-icon-kebab-filled {
		background-image: url(#{$azure-image-storage-base-url-test}Notes-color.svg);
	}
	.internal-notes-icon-kebab {
		background-image: url(#{$azure-image-storage-base-url-test}Internal-Notes.svg);
	}
	.internal-notes-icon-kebab-filled {
		background-image: url(#{$azure-image-storage-base-url-test}Internal-Notes-color.svg);
	}
	.status-icon-kebab {
		background-image: url(#{$azure-image-storage-base-url-test}status.svg);
	}
	.kebab-header-dropdown {
		.dropdown-item:focus,
		.dropdown-item:hover {
			.edit-icon-kebab {
				background-image: url(#{$azure-image-storage-base-url-test}Edit-active.svg);
			}
			.notes-icon-kebab {
				background-image: url(#{$azure-image-storage-base-url-test}Notes-active.svg);
			}
			.notes-icon-kebab-filled {
				background-image: url(#{$azure-image-storage-base-url-test}Notes-color-active.svg);
			}
			.internal-notes-icon-kebab {
				background-image: url(#{$azure-image-storage-base-url-test}Internal-Notes-active.svg);
			}
			.internal-notes-icon-kebab-filled {
				background-image: url(#{$azure-image-storage-base-url-test}Internal-Notes-color-active.svg);
			}
			.status-icon-kebab {
				background-image: url(#{$azure-image-storage-base-url-test}status-active.svg);
			}
		}
	}
}
.prodClass {
	.collapse-lines-icon{
		background: url(#{$azure-image-storage-base-url-prod}icon-collapse-lines.png);
	}
	.edit-icon {
		background: url(#{$azure-image-storage-base-url-prod}icon-editversion.svg) no-repeat;
	}
	.copy-url-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}icon-duplicate.svg);
	}
	.notes-icon-ql {
		background-image: url(#{$azure-image-storage-base-url-prod}Notes-blue.svg);
	}
	.search-clear-icon {
		background: url(#{$azure-image-storage-base-url-prod}icon-cancel.png);
	}
	.lock-icon {
		background: url(#{$azure-image-storage-base-url-prod}Lock.svg);
	}
	.lock-icon-grid {
		background: url(#{$azure-image-storage-base-url-test}Lock.svg);
	}
	.edit-icon-discount {
		background: url(#{$azure-image-storage-base-url-prod}edit-discount-icon.png) no-repeat;
	}
	.edit-icon-description {
		background: url(#{$azure-image-storage-base-url-prod}icon-editversion.svg) no-repeat;
	}
	.edit-icon-discount-second {
		background: url(#{$azure-image-storage-base-url-prod}edit-discount-icon.png) no-repeat;
	}
	.ok-icon-discount {
		background: url(#{$azure-image-storage-base-url-prod}ok-icon.png) no-repeat;
	}
	.icon-add-search-filter {
		background: url(#{$azure-image-storage-base-url-prod}icon-confirm.png);
	}
	.ok-btn {
		background: url(#{$azure-image-storage-base-url-prod}ok-icon.png) no-repeat;
	}
	.ok-icon-discount-second {
		background: url(#{$azure-image-storage-base-url-prod}ok-icon.png) no-repeat;
	}
	.cancel-icon-discount {
		background: url(#{$azure-image-storage-base-url-prod}cancel-icon.png) no-repeat;
	}
	.cancel-btn {
		background: url(#{$azure-image-storage-base-url-prod}cancel-icon.png) no-repeat;
	}
	.cancel-icon-discount-second {
		background: url(#{$azure-image-storage-base-url-prod}cancel-icon.png) no-repeat;
	}
	.icon-add-search-filter {
		background: url(#{$azure-image-storage-base-url-prod}icon-confirm.png);
	}
	.rbt-input {
		background-image: url(#{$azure-image-storage-base-url-prod}icon-search.svg);
	}
	.move-up {
		background-image: url(#{$azure-image-storage-base-url-prod}icon-configure.svg);
	}
	.move-down {
		background-image: url(#{$azure-image-storage-base-url-prod}icon-configure.svg);
	}
	.benor-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}benor-icon.png);
	}
	.doorWidth-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}doorWidth.png);
	}
	.doorHeight-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}doorHeight.png);
	}
	.doorThickness-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}Theuma-icoon-deurdikte.png);
	}
	.jambHeight-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}jambHeight.png);
	}
	.wallThickness-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}wallThickness.png);
	}
	.benor-icon-1713 {
		background-image: url(#{$azure-image-storage-base-url-prod}Benor-1713.png);
	}
	.benor-icon-2274 {
		background-image: url(#{$azure-image-storage-base-url-prod}Benor-2274.png);
	}
	.benor-icon-2287 {
		background-image: url(#{$azure-image-storage-base-url-prod}Benor-2287.png);
	}
	.benor-icon-3034 {
		background-image: url(#{$azure-image-storage-base-url-prod}Benor-3034.png);
	}
	.fireResistance-icon-30 {
		background-image: url(#{$azure-image-storage-base-url-prod}FireResistance30.png);
	}
	.fireResistance-icon-60 {
		background-image: url(#{$azure-image-storage-base-url-prod}FireResistance60.png);
	}
	.fireResistance-icon-ei130 {
		background-image: url(#{$azure-image-storage-base-url-prod}FireResistanceEI130.png);
	}
	.acousticResistance-icon-29dB {
		background-image: url(#{$azure-image-storage-base-url-prod}AcousticResistance-29.png);
	}
	.acousticResistance-icon-30dB {
		background-image: url(#{$azure-image-storage-base-url-prod}AcousticResistance-30.png);
	}
	.acousticResistance-icon-31dB {
		background-image: url(#{$azure-image-storage-base-url-prod}AcousticResistance-31.png);
	}
	.acousticResistance-icon-37dB {
		background-image: url(#{$azure-image-storage-base-url-prod}AcousticResistance-37.png);
	}
	.acousticResistance-icon-39dB {
		background-image: url(#{$azure-image-storage-base-url-prod}AcousticResistance-39.png);
	}
	.acousticResistance-icon-41dB {
		background-image: url(#{$azure-image-storage-base-url-prod}AcousticResistance-41.png);
	}
	.acousticResistance-icon-42dB {
		background-image: url(#{$azure-image-storage-base-url-prod}AcousticResistance-42.png);
	}
	.acousticResistance-icon-46dB {
		background-image: url(#{$azure-image-storage-base-url-prod}AcousticResistance-46.png);
	}
	.burglarResistance-icon-WK2 {
		background-image: url(#{$azure-image-storage-base-url-prod}BurglarResistance-WK2.png);
	}
	.burglarResistance-icon-WK3 {
		background-image: url(#{$azure-image-storage-base-url-prod}BurglarResistance-WK3.png);
	}
	.smokeResistance-icon-sas200 {
		background-image: url(#{$azure-image-storage-base-url-prod}SmokeResistanceSAS200.jpg);
	}
	.hygrothermalResistance-icon-IIb {
		background-image: url(#{$azure-image-storage-base-url-prod}HygrothermalResistance-IIb.png);
	}
	.hygrothermalResistance-icon-IIc {
		background-image: url(#{$azure-image-storage-base-url-prod}HygrothermalResistance-IIc.png);
	}
	.llc-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}llc-icon.png);
	}
	.doorWidthPassive-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}Theuma-icoon-deurbreedte-dubbel.png);
	}

	.move-down-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}actieve-pijl.svg);
	}
	.move-up-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}actieve-pijl.svg);
	}
	.kebab-menu {
		background-image: url(#{$azure-image-storage-base-url-prod}kebab-menu.png);
	}
	.add-quotation-line-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}icon-add-configuration.png);
	}
	.user-traces-approval-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}approval-icon.png);
	}
	.add-individual-artical-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}icon-add-product.png);
	}
	.share-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}Share.svg);
	}

	.lock-icon-grid {
		background-image: url(#{$azure-image-storage-base-url-prod}Lock.svg);
	}
	.unlock-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}UnLock.svg);
	}
	.export-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}icon-export.png);
	}
	.download-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}download-icon.png);
	}
	.download-without-line-icon{
		background-image: url(#{$azure-image-storage-base-url-prod}download-icon.png);
	}
	.search-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}icon-filter.png);
	}
	.search-icon-active {
		background-image: url(#{$azure-image-storage-base-url-prod}icon-filter-active.png);
	}
	.edit-icon {
		background: url(#{$azure-image-storage-base-url-prod}icon-editversion.svg) no-repeat;
	}
	.settings-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}icon-configure.svg);
	}
	.terms-and-conditions-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}terms-and-conditions-icon.png);
	}
	.delete-icon {
		background-image: url(#{$azure-image-storage-base-url-prod}icon-remove.svg);
	}
	.warning-red-icon {
		background: url(#{$azure-image-storage-base-url-prod}icon-error.png) no-repeat;
	}
	.history-icon {
		background: url(#{$azure-image-storage-base-url-prod}history.svg) no-repeat;
	}
	.discount-approval-icon 
	{
		background: url(#{$azure-image-storage-base-url-prod}discountApproval.svg) no-repeat;
	}
	.ok-btn {
		background: url(#{$azure-image-storage-base-url-prod}icon-confirm.png) no-repeat;
	}
	.dropdown-item:focus,
	.dropdown-item:hover {
		.configure-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-prod}icon-configure-active.png);
		}
		.edit-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}Edit-active.svg);
		}
		.delete-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-prod}icon-delete-active.png);
		}
		.discount-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-prod}discount-active.svg);
		}
		.discount-history-icon-kebab
		{
			background-image: url(#{$azure-image-storage-base-url-prod}history-kebab.png);
		}
		.euro-icon-kebab{
			background-image: url(#{$azure-image-storage-base-url-dev}euro-active.svg);
		}
		.quotationline-notes-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-prod}Notes-active.svg);
		}
		.attributes-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}Attributes-active.svg);
		}
		.alternative-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-prod}icon-alternative-active.png);
		}
		.copy-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-prod}icon-duplicate-active.png);
		}
		.activate-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}Activate-active.svg);
		}
	}
	.kebab-menu-dropdown {
		.configure-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-prod}icon-configure.png);
		}
		.edit-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}Edit.svg);
		}
		.delete-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-prod}icon-delete.png);
		}
		.discount-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-prod}discount.svg);
		}
		.discount-history-icon-kebab 
		{
			background-image: url(#{$azure-image-storage-base-url-prod}history-kebab.png);
		}
		.euro-icon-kebab{
			background-image: url(#{$azure-image-storage-base-url-dev}euro.svg);
		}
		.quotationline-notes-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-prod}Notes.svg);
		}
		.attributes-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}Attributes.svg);
		}
		.alternative-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-prod}icon-alternative.png);
		}
		.copy-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-prod}icon-duplicate.png);
		}
		.activate-icon-kebab {
			background-image: url(#{$azure-image-storage-base-url-dev}Activate.svg);
		}
	}
	.edit-icon-kebab {
		background-image: url(#{$azure-image-storage-base-url-prod}Edit.svg);
	}
	.notes-icon-kebab {
		background-image: url(#{$azure-image-storage-base-url-prod}Notes.svg);
	}
	.notes-icon-kebab-filled {
		background-image: url(#{$azure-image-storage-base-url-prod}Notes-color.svg);
	}
	.internal-notes-icon-kebab {
		background-image: url(#{$azure-image-storage-base-url-prod}Internal-Notes.svg);
	}
	.internal-notes-icon-kebab-filled {
		background-image: url(#{$azure-image-storage-base-url-prod}Internal-Notes-color.svg);
	}
	.status-icon-kebab {
		background-image: url(#{$azure-image-storage-base-url-prod}status.svg);
	}
	.kebab-header-dropdown {
		.dropdown-item:focus,
		.dropdown-item:hover {
			.edit-icon-kebab {
				background-image: url(#{$azure-image-storage-base-url-prod}Edit-active.svg);
			}
			.notes-icon-kebab {
				background-image: url(#{$azure-image-storage-base-url-prod}Notes-active.svg);
			}
			.notes-icon-kebab-filled {
				background-image: url(#{$azure-image-storage-base-url-prod}Notes-color-active.svg);
			}
			.internal-notes-icon-kebab {
				background-image: url(#{$azure-image-storage-base-url-prod}Internal-Notes-active.svg);
			}
			.internal-notes-icon-kebab-filled {
				background-image: url(#{$azure-image-storage-base-url-prod}Internal-Notes-color-active.svg);
			}
			.status-icon-kebab {
				background-image: url(#{$azure-image-storage-base-url-prod}status-active.svg);
			}
		}
	}
}
