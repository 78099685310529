@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

$azure-image-storage-base-url: 'https://sttheumaqtapi.blob.core.windows.net/qt-static-images/';
.conditions {
	display: flow-root;
}
.condition1 {
	width: 50%;
	float: left;
	height: 100%;
	padding: 10px;
}

.condition2 {
	width: 50%;
	float: left;
	height: 100%;
	padding: 10px;
}
.condition3 {
	width: 50%;
	float: left;
	height: 100%;
	padding: 10px;
}
.condition4 {
	width: 50%;
	float: left;
	height: 100%;
	padding: 10px;
}
.preview-image {
	background-repeat: no-repeat;
	background-position: center;
	background-size: 80px !important;
	width: 80px;
	height: 80px;
	margin-left: 2rem;
	cursor: pointer;
}
.preview-image-popup {
	background-repeat: no-repeat;
	background-position: center;
	background-size: 500px !important;
	width: 500px;
	height: 500px;
}
.preview-popup-body {
	display: flex;
	align-items: center;
	justify-content: center;
}
.image-selection-section {
	width: 60%;
	float: left;
	align-items: center;
	height: 100%;
}
.image-section {
	height: 80px;
	.dropdown-item {
		display: flex;
		align-items: center;
		padding: 5px;
		.dropdown-icon {
			background-repeat: no-repeat;
			background-position: center;
			background-size: 30px !important;
			width: 30px;
			height: 30px;
		}
		.dropdown-text {
			padding-left: 0.5rem;
		}
	}
}
.confirmationBody {
	white-space: pre-wrap;
}

.preview {
	width: 40%;
	display: flex;
	padding-left: 1rem;
	align-items: center;
}

.button-reset {
		float: right;
}

@include media-breakpoint-down(lg) {
	// Styles

	.condition1,
	.condition2,
	.condition3,
	.condition4 {
		width: 100%;
	}
}
