@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';
.view-quotation-panel {
	padding: 20px;
	border: 2px solid #cbcbcb;

	.edit-icon {
		background-size: 40px !important;
		width: 40px;
		height: 40px;
		float: right;
		cursor: pointer;
	}
}
.share-quotation {
	.share-quotaton-body {
		display: flex;
	}
	.link {
		float: left;
	}
	.copy-url-icon {
		background-size: 10px !important;
		padding: 10px;
		border: 2px solid rgba(255, 255, 255, 0.5);
		background-repeat: no-repeat;
		background-position: center;
		float: left;
		margin-left: 1rem;
		width: 10px;
		height: 10px;
		background-color: black;
		cursor: pointer;
	}
}
.notes-icon-ql {
	background-position: center;
	background-size: 50px !important;
	width: 60px;
	height: 60px;
	background-repeat: no-repeat;
	float: left;
	cursor: pointer;
}
.version-description {
	background-color: #f0f0f0;
	color: rgba(0, 0, 0, 0.6);
	padding: 5px 10px 5px 10px;
	font-size: 12px;
	font-weight: bold;
	float: left;
	max-width: 90%;
}
.version-description-input {
	width: 25%;
	float: left;
}
.search-clear {
	align-items: center;
	display: flex;
	height: 100%;
	width: 100%;

	.search-clear-icon {
		background-size: 35px !important;
		width: 35px;
		height: 35px;
		float: left;
		cursor: pointer;
	}
}
.edit-icon-discount {
	background-size: 18px !important;
	width: 20px;
	height: 25px;
	float: right;
	cursor: pointer;
	position: absolute;
	top: 35%;
	left: 101%;
}
.edit-icon-description {
	background-size: 25px !important;
	width: 25px;
	height: 25px;
	float: left;
	cursor: pointer;
}
.edit-icon-discount-second {
	background-size: 18px !important;
	width: 20px;
	height: 25px;
	float: right;
	cursor: pointer;
	position: absolute;
	bottom: 59%;
	left: 104%;
}
.ok-icon-discount {
	background-size: 21px !important;
	width: 20px;
	height: 25px;
	float: right;
	cursor: pointer;
}

.icon-add-search-filter {
	background-size: 35px !important;
	width: 35px;
	height: 35px;
	float: left;
	cursor: pointer;
}

.ok-btn {
	background-size: 21px !important;
	width: 20px;
	height: 25px;
	cursor: pointer;
}
.width-60 {
	width: 60% !important;
}
.ok-icon-discount-second {
	background-size: 21px !important;
	width: 20px;
	height: 25px;
	float: right;
	cursor: pointer;
	position: absolute;
	top: 8%;
	right: 37%;
}
.red-color-error {
	color: red;
	font-size: 11px;
	width: 200px;
	text-align: left;
	display: block;
}
.red-color-error-second {
	color: red;
	font-size: 11px;
	position: absolute;
	width: 70%;
	margin-right: 112%;
	float: left;
	text-align: left;
	display: block;
	top: 95%;
	left: 39%;
}
.discount-input {
	width: 50%;
	float: right;
}
.input-width {
	width: 100%;
}
.discount-input-second {
	width: 100%;
	float: right;
}
.discount-unit {
	width: 100px;
}
.edit-button-discount {
	width: 50%;
}
.input-width-second {
	width: 100%;
}

.cancel-icon-discount {
	background-size: 18px !important;
	width: 20px;
	height: 25px;
	float: right;
	cursor: pointer;
}
.cancel-btn {
	background-size: 18px !important;
	width: 20px;
	height: 25px;
	cursor: pointer;
}
.width-second {
	float: right;
	width: 100%;
}
.width-18 {
	width: 60%;
}
.cancel-icon-discount-second {
	background-size: 18px !important;
	width: 20px;
	height: 25px;
	float: right;
	cursor: pointer;
	position: absolute;
	top: 11%;
	right: 47%;
}
.header-row {
	color: #a0a0a0;
}
.bold-text {
	font-weight: 900 !important;
}
.multi-row-align {
	display: flex;
	flex-direction: column;
}

.quotation-line-add-button {
	float: left;
}

.econf-window {
	width: 1200px !important;
}
.add-quotation-line-modal-body {
	padding: 15px;
	.search-clear-icon {
		background-size: 35px !important;
		width: 35px;
		height: 35px;
		float: left;
		cursor: pointer;
		opacity: unset !important;
	}
	.icon-add-search-filter {
		background-size: 35px !important;
		width: 35px;
		height: 35px;
		float: left;
		cursor: pointer;
	}
}
.econ-config-modal-body {
	padding: 15px;
	height: 99%;
}
.export-modal-body {
	padding: 15px;
}
.language-panel {
	background-color: #f0f0f0 !important;
}
.add-quotation-line-panel {
	padding: 20px;
	background-color: #efefef;
	margin: 0px !important;
}

.view-quotation-panel-title {
	font-family: 'Roboto';
	font-size: 30px;
}

.quotation-panel-sub-title {
	font-size: 18px;
	font-weight: bold;
	display: inline-block;
	margin-bottom: 16px;
}

.hide-individualArticle-table {
	display: none;
}

.quotation-header {
	font-family: 'Roboto';
	font-size: 11px;
	font-weight: bold;
}

.quotation-content {
	font-family: 'Roboto';
	font-size: 15px;
}

.quotation-line-add-button-btn {
	border: 2px solid #e42312 !important;
	height: 70px !important;
	color: #e42312 !important;
	font-size: 16px !important;
	font-weight: bold !important;
	width: 100% !important;
	background-color: #fff !important;
	border-radius: 3px;
}

.add-quotation-line-title {
	opacity: 0.6;
	color: #000000;
	font-size: 11px;
	font-weight: bold;
	padding-bottom: 5px;
}
.add-individual-article-title {
	opacity: 0.6;
	color: #000000;
	font-size: 14px;
	font-weight: bold;
	padding-bottom: 5px;
}
.dropdown .dropdown-toggle::after {
	margin-top: 10px;
}
.font-weight-doors {
	font-weight: 400 !important;
}
.add-quotation-line-panel-label {
	font-weight: bold;
	font-size: 16px;
}

.line-item-table-header {
	font-size: 11px;
	opacity: 0.6;
	font-weight: bold;
}
.line-item-table-content {
	font-size: 11px;

	font-weight: bold;
}
.line-item-input {
	width: 100%;
}
.line-item-table-header th {
	border-top: 0px !important;
	border-bottom: 0px !important;
}

.add-quotation-line-modal-body .table tbody td {
	border-top: 0px;
	border-bottom: 0px !important;
	padding: 0.75rem 10px 0.75rem 0px;
	opacity: 0.6;
	&:last-child {
		opacity: 1 !important;
	}
	&:first-child {
		text-align: end;
	}
	& input {
		border: 1px solid #cccccc;
	}
}

.doorway-details {
	max-height: 30vh;
	overflow-y: auto;
	overflow-x: hidden;
}

.discount-trace-body {
	padding: 15px;
	.line-title {
		font-weight: bold;
	}
}
.quotation-status-modal-body .table tbody td {
	border-top: 0px;
	border-bottom: 0px !important;
}

.quotation-status-modal-body .table tbody td input {
	border: 1px solid #9c9c9c;
}

.quotation-status-modal-body .table tbody tr {
	border-bottom: 1px dashed #d3d3d3;
}

.modal-lg {
	max-width: 80%;
}

.quotation-status-modal-body .table thead th {
	border-top: none !important;
}
.bold-header-font {
	color: #000;
	opacity: 0.6;
	font-weight: bold;
	font-size: 13px;
	padding-bottom: 5px;
}

input[type='radio'] {
	border: 0px;
	width: 100%;
	height: 1.2em;
}

.modal-header {
	border-bottom: none !important;
}
.modal-footer {
	border-top: none !important;
}

.modal-title {
	width: 100% !important;
}

.modal-title-text {
	float: left;
}

.modal-close {
	font-weight: bold;
	font-size: 20px;
	float: right;
	margin-right: 20px;
	cursor: pointer;
}

.opportunity-next-step-btn {
	font-family: 'Roboto';
	font-size: 16px !important;
	font-weight: bold !important;
	color: #fff !important;
	background-color: #ef2312 !important;
}

.modal-footer-button {
	float: left;
	width: 100%;
}
/* doorway panel styles  */
.doorway-panel {
	border: 2px solid #f0f0f0;

	.heading-left-arrow {
		height: 100%;
	}
	.backgroundColorBlue {
		background-color: #fdeadf !important;
		color: #000000 !important;
	}

	.background-white {
		background-color: white !important;
	}

	.border-red {
		border: 1px solid #cc837c !important;
	}

	.attributes {
		.subtable {
			tr {
				td {
					border-color: transparent !important;
					word-break: break-word;
				}

				td:nth-last-child(2) {
				}

				td:nth-last-child(1) {
				}

				td:last-child {
					border-left: 1px solid black !important;
					border-right: 1px solid black !important;
				}
			}

			tr:first-child td:last-child {
				border-top: 1px solid black !important;
			}

			tr:last-child td:last-child {
				border-bottom: 1px solid black !important;
			}

			tr:nth-last-child(1) {
				td {
					border-bottom: transparent !important;
				}
			}

			tr {
				border-top: 1px solid #c0c0c0;
				border-left: 1px solid #c0c0c0;
				line-height: 14px;

				td:first-child {
					padding-left: 20px;
				}
			}

			tr:last-child {
				border-bottom: 1px solid #c0c0c0;
			}
		}

		background-white {
			border: 2px solid #cc837c !important;
		}

		background-white:nth-last-child(2) {
			border: transparent !important;
		}

		background-white:nth-last-child(1) {
			border: transparent !important;
		}

		tr:nth-last-child(1) {
			td {
			}
		}
	}

	.heading-left {
		background-color: #e42312;
		color: #ffffff;
		padding: 12px;
		cursor: pointer;

		&-arrow {
			float: left;
			color: white;
			width: 30px;
			background-color: #e42312;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&-content {
			float: left;

			&-head {
				color: #ffffff;
				font-size: 16px;
				text-transform: uppercase;
				font-weight: 500;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&-sub {
				color: #ffffff;
				font-size: 12px;
				text-transform: uppercase;
				opacity: 0.66;
			}
		}

		.move-up {
			background-size: 10px !important;
			height: 20px;
			width: 44px;
			border: 2px solid rgba(255, 255, 255, 0.5);
			background-repeat: no-repeat;
			background-position: center;
			float: right;
			margin-bottom: 4px;
		}
		.arrow-btn {
			flex-direction: column;
			margin-top: -10px;
		}
		.move-down {
			background-size: 10px !important;
			height: 20px;
			width: 44px;
			border: 2px solid rgba(255, 255, 255, 0.5);
			background-repeat: no-repeat;
			background-position: center;
			float: right;
		}
		.icon-disabled {
			opacity: 0.5;
			cursor: not-allowed;
		}
	}

	.heading-right {
		width: 52%;
		.benor-icon {
			background-size: 40px !important;
			width: 40px;
			height: 40px;
			float: left;
			margin-top: 12px;
		}

		.door-width {
			background-color: #f0f0f0;
			background-size: 40px !important;
			width: 40px;
			height: 40px;
			float: left;
			border-top: 2px solid #000;
			border-bottom: 2px solid #000;
			font-size: 11px;
			text-align: center;
			font-weight: bold;
			margin-top: 10px;
			font-size: 11px;
		}

		.door-height {
			background-color: #f0f0f0;
			background-size: 40px !important;
			width: 40px;
			height: 40px;
			float: left;
			border-left: 3px solid #000;
			border-right: 3px solid #000;
			font-size: 11px;
			text-align: center;
			padding-top: 4px;
			font-weight: bold;
			margin-top: 10px;
			font-size: 11px;
		}

		.door-frame {
			background-color: #f0f0f0;
			background-size: 40px !important;
			width: 40px;
			height: 40px;
			float: left;
			border: 3px solid #000;
			font-size: 11px;
			text-align: center;
			padding-top: 4px;
			font-weight: bold;
			margin-top: 10px;
			font-size: 11px;
		}

		.door-hinges {
			background-color: #f0f0f0;
			background-size: 40px !important;
			width: 40px;
			height: 40px;
			float: left;
			border-left: 3px solid #000;
			border-top: 3px solid #000;
			border-bottom: 3px solid #000;
			font-size: 11px;
			text-align: center;
			padding-top: 4px;
			font-weight: bold;
			margin-top: 10px;
			font-size: 11px;
		}

		.door-plane {
			background-color: #f0f0f0;
			background-size: 40px !important;
			width: 40px;
			height: 40px;
			float: left;
			font-size: 11px;
			text-align: center;
			padding-top: 4px;
			font-weight: bold;
			margin-top: 10px;
			font-size: 11px;
		}

		.alignCenter {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}

		.doorWidth-icon,
		.doorHeight-icon,
		.doorThickness-icon,
		.jambHeight-icon,
		.benor-icon-1713,
		.benor-icon-2274,
		.benor-icon-2287,
		.benor-icon-3034,
		.fireResistance-icon-30,
		.fireResistance-icon-60,
		.fireResistance-icon-ei130,
		.acousticResistance-icon-29dB,
		.acousticResistance-icon-30dB,
		.acousticResistance-icon-31dB,
		.acousticResistance-icon-37dB,
		.acousticResistance-icon-39dB,
		.acousticResistance-icon-41dB,
		.acousticResistance-icon-42dB,
		.acousticResistance-icon-46dB,
		.burglarResistance-icon-WK2,
		.burglarResistance-icon-WK3,
		.smokeResistance-icon-sas200,
		.hygrothermalResistance-icon-IIb,
		.hygrothermalResistance-icon-IIc,
		.wallThickness-icon,
		.llc-icon {
			background-size: 60px;
			width: 60px;
			height: 60px;
			background-repeat: no-repeat;
			font-weight: bold;
			font-size: 11px;
			float: left;
		}
		.doorWidthPassive-icon {
			float: left;
			background-size: 105px;
			width: 105px;
			height: 60px;
			background-repeat: no-repeat;
			font-weight: bold;
			font-size: 11px;
		}

		&-content {
			background-color: #f0f0f0;
			color: #000000;
			font-weight: 600;
			padding-top: 16px;
			min-height: 66px;
			text-align: center;
		}
	}

	.panel-content {
		background-color: #f0f0f0;

		padding: 12px;

		table.content-table {
			width: 100%;
			background: white;
			padding: 16px;
			margin-bottom: 16px;
			table-layout: fixed;

			tr {
				th,
				td {
				}

				th {
					padding: 12px;
				}

				th:first-child {
					width: 25%;
				}

				th:last-child {
					background-color: #f0f0f0;
					text-align: right;
					border-color: #f0f0f0;
				}
			}

			.middle-class {
				tr {
					th:last-child {
						background-color: transparent;
						text-align: left;
						border-bottom: 1px solid #c0c0c0;
					}
				}
			}

			tr {
				td:last-child {
					background-color: #f0f0f0;
					border-color: #f0f0f0;
				}
			}

			.middle-class {
				tr {
					td:last-child {
						background-color: transparent;
						text-align: left;
						border-color: transparent;
					}
				}
			}
		}

		table.content-table-class {
			width: 100%;
			background: white;
			padding: 16px;
			margin-bottom: 16px;

			tr {
				th,
				td {
				}

				th {
					padding: 17px;
				}

				th:first-child {
					width: 65%;
				}

				th:last-child {
					background-color: #fdeadf;
					text-align: right;
					border-top: 1px solid #000000;
					border-left: 1px solid #000000;
					border-right: 1px solid #000000;
					border-bottom: 1px solid #bbbbbb;
				}
			}

			.middle-class {
				tr {
					th:last-child {
						background-color: transparent;
						text-align: left;
						border-bottom: 1px solid #c0c0c0;
					}
				}
			}

			tr {
				td:last-child {
					background-color: #fdeadf;
				}
			}

			.middle-class {
				tr {
					td:last-child {
						background-color: transparent;
						text-align: left;
						border-color: transparent;
					}
				}
			}
		}

		table.subtable {
			background-color: white;
			width: 100%;

			tr {
				th,
				td {
					padding: 8px;
					border: 0;
					border-bottom: 1px solid #c0c0c0;
				}

				th {
					padding: 12px;
				}

				th:first-child {
					width: 65%;
				}

				th:last-child {
					background-color: white;
					text-align: left;
					border-color: #c0c0c0;
				}

				td:last-child {
					background-color: white;
					text-align: left;
					border-color: #c0c0c0;
				}
			}

			tr:last-child {
				td {
					border: 0;
				}
			}
		}

		.empty-left {
			background-color: #f0f0f0;
		}
	}

	.panel-footer-total {
		background-color: #cc837c;
		padding-top: 12px;
		padding-bottom: 12px;
		padding-right: 29px;

		table.footer-table {
			width: 100%;

			th,
			td {
				padding: 8px;
			}

			td:first-child {
				width: 60%;
			}
		}
	}

	.panel-footer {
		background-color: #fef6f3;
		border: 2px solid #cc837c;
		padding-top: 12px;
		padding-bottom: 12px;
		padding-right: 29px;

		table.footer-table {
			width: 100%;

			th,
			td {
				padding: 8px;
			}

			td:first-child {
				width: 65%;
			}
		}
	}

	.two-cols {
		float: left;
		text-align: left;
	}

	.footer-text {
		font-weight: 300;
	}

	.two-cols-end {
		text-align: right;
		float: right;
		position: relative;
	}
}

.modal-content {
	height: 95% !important;
}
.margin-padding-left {
	margin-left: 0px !important;
	padding-left: 0px !important;
}
.mb-5-changes {
	margin-bottom: 2rem !important;
}
.green {
	color: #000000 !important;
	font-weight: bold;
}
.blue-color {
	color: #e42312;
	font-size: 11px;
	font-weight: bold;
	border-bottom: 1px solid #c0c0c0;
}
.attributes-text {
	color: #000000;
	font-size: 15px;
	font-weight: normal;
	padding: 8px 15px;
}
.light-white {
	color: #ffffff;
	font-size: 15px;
	font-weight: lighter;
}
.bottom-border-grey {
	border-bottom: 1px solid #c0c0c0 !important;
}
.bold-white {
	color: #ffffff;
	font-size: 15px;
	font-weight: bold;
}
.line-details-space {
	padding-bottom: 39px;
	border-bottom: 1px solid #bbbbbb;
}
.border-bottom-building-details {
	border-bottom: #c0c0c0;
}

.padding-left-40 {
	padding-left: 40px !important;
}
.padding-left-50 {
	padding-left: 50px !important;
}

.padding-top-30 {
	padding-top: 30px !important;
}

.background-color-FEF6F3 {
	background-color: #fef6f3;
}

.margin-width-inner-attributes {
	margin: 15px;
	width: 65%;
}

.vertical-align-top-background-color {
	background-color: rgb(254, 246, 243) !important;
	vertical-align: top;
}
.padding-top-27 {
	padding-top: 27px !important;
}
.cursor-pointer {
	cursor: pointer;
	color: #959595;
}

.font-weight-300 {
	font-weight: 300;
}
.background-color-white {
	background-color: white;
}

.padding-left-bottom {
	padding: 0px 0px 15px 15px;
}

.border-left-top-bottom-BBBBBB {
	border-left: 1px solid #bbbbbb;
	border-bottom: 1px solid #bbbbbb;
	border-top: 1px solid #bbbbbb;
}
.line-name {
	padding-left: 22px;
	padding-top: 15px;
	padding-bottom: 15px;
	font-size: 18px;
	font-weight: bold;
	border-bottom: 1px solid #bbbbbb;
	width: 100%;
}
.bruto-td {
	vertical-align: top;
	background-color: #fdeadf;
	border-left: 1px solid #000000;
	border-right: 1px solid #000000;
	border-bottom: 1px solid #bbbbbb;
	padding: 10px;
}
.bruto-inner-td {
	padding-bottom: 22px;
	border-bottom: 1px solid #bbbbbb;
	font-weight: 300;
}

.bruto-price {
	text-align: right;
	border-bottom: 1px solid #bbbbbb;
	padding-bottom: 21px;
}
.bruto-net-td {
	border-left: 1px solid #000000;
	border-right: 1px solid #000000;
	border-top: 1px solid #bbbbbb;
	border-bottom: 1px solid #bbbbbb;
	padding: 10px;
	font-weight: 300;
}
.netto-quantity {
	border-left: 1px solid #000000;
	border-right: 1px solid #000000;
	border-bottom: 1px solid #bbbbbb;
	padding: 10px;
	font-weight: 300;
}

.border-bottom-f0f0f0 {
	border-bottom: 1px solid #f0f0f0;
}

.netto-margin {
	border: 1px solid #000000;
	padding: 10px;
	font-weight: 300;
}

.padding-left-22 {
	padding-left: 22px !important;
}

.table-layout {
	width: 100%;
	table-layout: fixed;
}

.padding-top-title {
	padding-top: 10px;
}
.width-left {
	float: left;
	width: 35px !important;
}
.width-left-second {
	float: left;
	width: 30px !important;
}
.export-title {
	font-size: 15px !important;
	display: inline;
}
.display-inline-export {
	display: inline-block;
	width: 70%;
}
.disabled-dropdown {
	button {
		background-color: #f0f0f0 !important;
		cursor: not-allowed !important;
	}
}
.padding5px {
	padding: 5px;
}
.line-items-table {
	background-color: white;
	font-family: 'Roboto' !important;

	tr td {
		padding-right: 29px;
		word-break: break-word;
	}

	tr td.grey {
		background-color: #f0f0f0;
		border: 0;
	}

	tr td:last-child {
		background-color: #fdeadf;
		border-left: 1px solid black;
		border-right: 1px solid black;
	}

	tr:first-child td {
		height: 0;
		margin: 0;
		padding: 0;
		border-bottom: 0 !important;
		background-color: #f0f0f0 !important;
	}

	tr:first-child td:last-child {
		border: 0 !important;
		background-color: #f0f0f0 !important;
	}

	tr:last-child td:last-child {
		border-bottom: 1px solid black !important;
	}
	tr:last-child {
		border-bottom: 1px solid #f0f0f0 !important;
	}
	tr:nth-child(2) {
		td:first-child {
			border-top: 0 !important;
		}
	}
}
.border-top-table {
	border-top: 1px solid black !important;
	border-left: 1px solid black !important;
	border-right: 1px solid black !important;
	text-align: right;
}
.input-width {
	width: 48px;
	text-align: center;
}
.bluecolor {
	color: #4490df;
}
.individual-search-table-header th {
	color: #000000;
}

.quantity-font {
	color: #959595;
}

.individual-row {
	cursor: pointer;

	&:hover {
		background-color: rgb(62, 100, 139) !important;
	}
}

.div-table {
	padding: 0px 20px 0px 20px;
	background-color: white;
	width: 80%;
	border: 1px solid #dee2e6;
	max-height: 300px;
	overflow-x: hidden;
	overflow-y: scroll;
}
.line-detail-name {
	font-family: 'Roboto';
	font-size: 18px;
	font-weight: bold;
	word-break: break-word !important;
	line-height: normal !important;
}
.first-row {
	border-top: 1px solid #c0c0c0;
}
.content-row {
	line-height: 14px;
	td {
		border-top: none !important;
		padding: 0.5rem !important ;
	}
	border-left: 1px solid #c0c0c0;
	td:first-child {
		padding-left: 30px !important;
	}
}
.fittings-label {
	padding-left: 7% !important;
}
.fitting-row {
	td:first-child {
		padding-left: 6% !important;
	}
}
.last-row {
	border-bottom: 1.1px solid #c0c0c0;
}
.total-sum {
	font-size: 18px;
	font-weight: bold;
}
.add-quotation-line-content {
	font-size: 16px;
}
.pricelist-dropdown {
	width: 50%;
	.dropdown {
		border: 1px solid #d0d6dc;
	}
}

.supplement-quantity {
	width: 100%;
	float: left;
	padding-right: 10px;
}

.pricelist-dropdown-supplement {
	width: 100%;
	.dropdown {
		border: 1px solid #d0d6dc;
	}
}
.artical-search-supplement {
	input {
		width: 100% !important;
	}
}
.artical-search {
	input {
		width: 50% !important;
	}
}
.order-buttons {
	display: flex;
	flex-direction: column;
	float: right;
}
@include media-breakpoint-down(lg) {
	// Styles
	.view-quotation-panel-title {
		font-size: 18px !important;
	}

	.quotation-panel-sub-title {
		font-size: 16px !important;
	}
	.line-detail-name {
		font-size: 16px;
	}
	.total-sum {
		font-size: 16px;
	}
	.add-quotation-line-content {
		font-size: 14px;
	}
	.doorWidth-icon,
	.doorHeight-icon,
	.doorThickness-icon,
	.jambHeight-icon,
	.benor-icon-1713,
	.benor-icon-2274,
	.benor-icon-2287,
	.benor-icon-3034,
	.fireResistance-icon-30,
	.fireResistance-icon-60,
	.fireResistance-icon-ei130,
	.acousticResistance-icon-29dB,
	.acousticResistance-icon-30dB,
	.acousticResistance-icon-31dB,
	.acousticResistance-icon-37dB,
	.acousticResistance-icon-39dB,
	.acousticResistance-icon-41dB,
	.acousticResistance-icon-42dB,
	.acousticResistance-icon-46dB,
	.burglarResistance-icon-WK2,
	.burglarResistance-icon-WK3,
	.smokeResistance-icon-sas200,
	.hygrothermalResistance-icon-IIb,
	.hygrothermalResistance-icon-IIc,
	.wallThickness-icon,
	.llc-icon {
		background-size: 45px !important;
		width: 45px !important;
		height: 45px !important;
		line-height: 10px;
	}
	.doorWidthPassive-icon {
		background-size: 88px !important;
		width: 88px !important;
		height: 50px !important;
		line-height: 10px;
	}
	.add-quotation-line-icon,
	.user-traces-approval-icon,
	.add-individual-artical-icon,
	.share-icon,
	.lock-icon,
	.unock-icon,
	.export-icon,
	.download-icon,
	.pdfjson-icon,
	.download-without-line-icon,
	.search-icon {
		padding: 17px !important;
		background-size: 40px !important;
	}
	.simple-search {
		padding-right: 50px !important;
	}
	.add-quotation-line-panel-label {
		font-size: 14px;
	}
	.notes-icon-ql {
		background-size: 40px !important;
		width: 50px !important;
		height: 50px !important;
	}
}
.move-down-icon {
	height: 50%;
	background-color: #fef6f3;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}
.move-up-icon {
	height: 50%;
	background-color: #fef6f3;
	background-repeat: no-repeat;
	background-position: center;
	transform: rotate(180deg);
	cursor: pointer;
}
.icon-disabled {
	opacity: 0.5;
	cursor: not-allowed;
}
.move-buttons {
	display: flex;
	flex-direction: column;
	width: 25px;
	height: 100%;
}
.sequence-number {
	background-color: #e42312;
	color: #fff;
	width: 35px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	border-right: 1px solid #f8a7a7;
}
.kebab-menu {
	width: 25px;
	height: 60px;
	background-color: #e42312;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 5px !important;
}

.header-row-view {
	height: 60px;
}
.discountQLineNotification {
	background-color: #fef6f3;
}
.heading-left-content {
	background-color: #e42312;
	width: 30%;
	justify-content: center;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: 100%;
}
.right-panel-container {
	padding: 0 !important;
	.right-panel {
		background-color: #f0f0f0;
		align-items: center;
		height: 60px;
		display: flex;

		.quantity {
			background-color: white;
			height: 80%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 1%;
			width: 10%;
			font-weight: bold;
			span {
				opacity: 0.6;
			}
		}
		.description {
			max-height: 90%;
			overflow: hidden;
			text-overflow: ellipsis;
			padding: 1%;
			width: 55%;
			white-space: pre-line;
			font-weight: bold;
			span {
				opacity: 0.6;
			}
		}

		.net-unit-price {
			background-color: white;
			height: 80%;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 15%;
			font-weight: bold;
			span {
				opacity: 0.6;
			}
		}
		.net-price {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #fef6f3;
			border: 1px solid #e42312;
			width: 20%;

			font-weight: bold;
		}
	}
}
.width10 {
	width: 10%;
}
.width15 {
	width: 20%;
}
.width50 {
	width: 50%;
}
.width30 {
	width: 30%;
}
.width63 {
	width: 63%;
}
.highlight {
	color: #baba3f !important;
	&:hover {
		color: #ffff8e !important;
	}
}

.kebab-menu-dropdown {
	.dropdown-menu {
		background-color: #000;
		color: #fff;
		min-width: 11rem !important;
		padding-left: 10px;
		padding-right: 10px;
		&::-webkit-scrollbar {
			width: 0px;
			background: transparent;
			/* make scrollbar transparent */
		}
		.dropdown-item {
			border-bottom: 1px solid #7c7f80;
		}
		.dropdown-item:last-child {
			border: none;
		}
	}

	.dropdown-item:focus,
	.dropdown-item:hover {
		background-color: #000;
		font-weight: bold;

		.kebab-option-desc {
			color: #fff;
		}
	}
	button {
		padding: 0;
		border: none;
		border-radius: 0 !important;
	}
	.kebab-option {
		display: flex;
		align-items: center;
		padding: 5px;
	}

	.kebab-option-icon {
		float: left;
	}
	.kebab-option-desc {
		float: left;
		color: #7c7f80;
	}
	.configure-icon-kebab,
	.edit-icon-kebab,
	.delete-icon-kebab,
	.discount-icon-kebab,
	.discount-history-icon-kebab,
	.quotationline-notes-icon-kebab,
	.attributes-icon-kebab,
	.alternative-icon-kebab,
	.euro-icon-kebab,
	.activate-icon-kebab,
	.copy-icon-kebab {
		width: 25px;
		height: 25px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 15px !important;
	}

	.activate-icon-kebab {
		background-size: 19px !important;
	}
}
.kebab-header-dropdown {
	float: right;
	.kebab-menu-header {
		width: 40px !important;
		height: 40px !important;
	}

	.status-icon-kebab {
		background-size: 30px !important;
	}

	.status-icon-kebab,
	.edit-icon-kebab,
	.notes-icon-kebab,
	.notes-icon-kebab-filled,
	.internal-notes-icon-kebab-filled,
	.internal-notes-icon-kebab {
		width: 25px;
		height: 25px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: 15px;
	}
}
.add-quotation-line-icon,
.user-traces-approval-icon,
.add-individual-artical-icon,
.share-icon,
.lock-icon,
.unlock-icon,
.export-icon,
.download-icon,
.pdfjson-icon,
.download-without-line-icon,
.search-icon,
.search-icon-active {
	padding: 20px;
	border: 2px solid rgba(255, 255, 255, 0.5);
	background-repeat: no-repeat !important;
	background-position: center;
	position: fixed;
	right: 1%;
	border-radius: 30px;
	cursor: pointer;
	&:hover {
		padding: 21px;
	}
}
.user-traces-approval-icon {
	top:100px;
}
.add-quotation-line-icon {
	top: 145px;
}

.add-individual-artical-icon {
	top: 190px;
}
.share-icon {
	background-size: 25px !important;
	background-color: #333333;
	top: 235px;
}
.export-icon {
	top: 280px;
}
.search-icon {
	top: 325px;
}
.search-icon-active {
	top: 325px;
}
.download-icon {
	top: 370px;
}
.pdfjson-icon
{
	top:415px;
	background-size: 40px !important;
}
.download-without-line-icon {
	top: 280px;
}
.lock-icon {
	background-size: 42px !important;
	background-color: #ffffff;
	top: 370;
}
.unlock-icon {
	background-size: 42px !important;
	background-color: #ffffff;
	top: 370px;
}
.disable-lock-icon {
	pointer-events: none;
	cursor: default !important;
}

.set-table {
	font-family: 'Roboto' !important;
	td {
		border: none !important;
	}
	tr {
		background-color: white;
		border: 1px solid #c0c0c0 !important;
	}

	tr td:last-child {
		background-color: #fdeadf;
		border: 1px solid black !important;
		padding-right: 29px;
	}
}
.upselling-data {
	.upselling-title {
		font-weight: bold;
		margin-bottom: 0.5rem;
	}
	.row {
		padding: 10px;
		background-color: white;
	}
	.table {
		margin-top: 0.5rem;
		margin-bottom: none;
	}
	.row:not(:last-child) {
		margin-bottom: 5px;
	}
}
.settings-icon {
	background-size: 22px !important;
	padding: 20px;
	border: 2px solid rgba(255, 255, 255, 0.5);
	background-repeat: no-repeat;
	background-position: center;
	float: right;
	background-color: #e42312;
	cursor: pointer;
}
.delete-icon {
	padding: 20px;
	border: 2px solid rgba(255, 255, 255, 0.5);
	background-repeat: no-repeat;
	background-position: center;
	float: right;
	background-color: #e42312;
	background-size: 22px !important;
	cursor: pointer;
}
.no-padding {
	padding: 0 !important;
}
.search-criteria {
	border: 2px solid #f0f0f0;
	.clear-search-button {
		float: right;
	}
}

.collapse-lines {
	border: 2px solid #f0f0f0;

}

.collapse-lines-icon {
	height: 35px;
	width: 35px;
	background-size: 20px; 
	background-color: #e42312;
	background-repeat: inherit;
	background-position: center;
	cursor: pointer;
}

.search-popup {
	input {
		width: 100% !important;
		height: calc(1.5em + 0.75rem + 11px);
	}
	.rbt-menu {
		width: 100% !important;
		.dropdown-item.disabled {
			display: none;
		}
	}
}
.search-modal-popup .table tbody td {
	padding: 0.75rem;
}
.price-on-request {
	float: right;
	color: #e42312;
}
.color-red {
	color: #e42312;
}

.color-green {
	color: #12e451;
}
.warning-red-icon {
	background-size: 25px !important;
	width: 25px;
	height: 25px;
	float: left;
}
.version-details {
	.ok-btn {
		float: left;
	}
	.cancel-btn {
		float: left;
	}
}
.history-icon {
	background-size: 25px !important;
	width: 25px;
	height: 25px;
	float: left;
	cursor: pointer;
	margin-left: 0.5rem;
}
.discount-approval-icon {
	background-size: 25px !important;
	width: 25px;
	height: 25px;
	float: left;
	cursor: pointer;
	margin-left: 0.5rem;
}
.discount-trace-data {
	max-height: 50vh;
	overflow-x: hidden;
	overflow-y: auto;
}
.float-left {
	float: left;
}
.float-right {
	float: right;
}
.simple-search {
	.ok-btn {
		background-size: 25px !important;
		width: 25px;
		height: 25px;
		float: right;
	}
	.cancel-btn {
		float: right;
	}
	input {
		float: right;
		width: 40%;
	}
	.search-title {
		float: right;
	}
}
.alternative-color {
	background-color: grey !important;
	border: none !important;
}

.alternative-sequence {
	border-right: 1px solid #c5c2c2 !important;
}
.alternative-net-price {
	border: 1px solid grey !important;
	background-color: #f0f0f0 !important;
	opacity: 0.6;
}
.attributes-body {
	max-height: 70vh;
	overflow-x: auto;
	.modal-title {
		font-weight: bold;
	}
}
.error-message-excel {
	color: red;
}
.upload-excel-btn {
	float: right;
}
.notes-text-area {
	max-height: 50vh;
	height: 50vh;
	border: 2px solid #cbcbcb !important;
}

.discount-approval-text-area {
	max-height: 20vh;
	height: 10vh;
	border: 2px solid #cbcbcb !important;
}
.header-comments-text-area {
	max-height: 20vh;
	height: 20vh;
	border: 2px solid #cbcbcb !important;
}
.discount-comments-text-area {
	max-height: 20vh;
	height: 20vh;
	border: 2px solid #cbcbcb !important;
}
.notes-language-dropdown {
	float: right;
	.dropdown-toggle {
		border: 2px solid #cbcbcb !important;
	}
}
.notes-language-title {
	float: left;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
}
.notes-language {
	align-items: center;
	justify-content: center;
	vertical-align: middle;
}
