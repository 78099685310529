@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

.standardNotes {
	max-height: 43vh;
	overflow: auto;
	width: 100%;
	.icon-add-search-filter {
		background-repeat: no-repeat !important;
		float: right !important;
		background-size: 25px !important;
		width: 25px !important;
		height: 25px !important;
	}
}

.standard-notes-content {
	padding: 5px;
	border: 2px solid #cbcbcb;
	border-radius: 9px;
}
