@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';

.languagetranslation {
	max-height: 43vh;
	overflow: auto;
	width: 100%;

	.icon-add-search-filter {
		background-repeat: no-repeat !important;
		float: right !important;
		background-size: 20px !important;
		width: 25px !important;
		height: 25px !important;
	}
}

.body-text-area {
	max-height: 30vh;
	height: 30vh;
	border: 2px solid #cbcbcb !important;
}

.padding5px {
	padding: 5px;
}

.language-translation-content {
	padding: 7px;
	border: 2px solid #cbcbcb;
	border-radius: 6px;
}
.dashboard-header {
	width: 100%;
	height: 70px;
	border-bottom: 2px solid #efefef;
}

.dashboard-content {
	padding: 0 100px 20px 20px;
}

.opportunity-number,
.account-number {
	font-size: 10px;
	color: #000;
	opacity: 0.5;
}
.loading {
	width: 100%;
	text-align: center;
}
.arrow-up {
	content: ' ';
	position: absolute;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent #a0a0a0 transparent;
	vertical-align: top;
	margin-top: -14px;
}

.arrow-down {
	content: ' ';
	position: absolute;
	border-width: 5px;
	border-style: solid;
	border-color: #a0a0a0 transparent transparent transparent;
	vertical-align: bottom;
}
.black-color-up {
	content: ' ';
	position: absolute;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent #000 transparent;
	vertical-align: top;
	margin-top: -14px;
}
.padding-td-version {
	padding-left: 4% !important;
}
.display-inline {
	display: inline-block;
}
.display-block {
	display: block !important;
}
.black-color-down {
	content: ' ';
	position: absolute;
	border-width: 5px;
	border-style: solid;
	border-color: #000 transparent transparent transparent;
	vertical-align: bottom;
}
.arrow-buttons {
	margin-top: 10px;
	float: left;
}
.version-data {
	max-height: 200px;
	overflow-x: hidden;
	overflow-y: auto;
}
.table-header-content {
	margin-left: 20px;
	font-size: 11px;
	font-weight: bold;
	color: #a0a0a0;
	white-space: normal;
	word-break: break-work !important;
}
.word-break {
	word-break: break-word !important;
	overflow: hidden;
	white-space: normal;
	display: table-cell;
}
.dashboard-content > tbody > tr > td {
	background-color: red;
}

.dashboard-title {
	font-family: 'Roboto';
	font-size: 30px;
}
.padding-td {
	//padding-left: 35px !important;
	cursor: pointer;
}
.btn-color {
	background-color: #e42312;
}

.filter-icon {
	background-size: 40px !important;
	width: 40px;
	height: 40px;
	cursor: pointer;
	display: inline-block;
}

.display-inline {
	display: inline;
}
.dashboard-table {
	thead th {
		vertical-align: top !important;
		cursor: pointer;
	}
}
.table-container {
	margin: 15px;
}
.sub-content {
	font-size: 12px;
}

.table-content {
	font-size: 14px;
}

.main-content {
	font-size: 15px;
	font-weight: bold;
}

.search-daterange {
	input {
		height: calc(1.5em + 0.75rem + 11px) !important;
	}
}
.search-clear-section {
	display: flex;
	align-items: center;
	.search-clear-icon {
		background-size: 35px !important;
		width: 35px;
		height: 35px;
		float: left;
		cursor: pointer;
	}
}

.lock-icon-grid {
	background-size: 30px !important;
	width: 30px;
	height: 30px;
	float: right;
	cursor: pointer;
	background-repeat: no-repeat !important;
	background-position: center;
}

.disable-lock-icon {
	pointer-events: none;
	cursor: default !important;
}

.dashboard {
	.rbt-input {
		width: 100% !important;
	}
}

.preview-box {
	position: relative;
	background: #fafafa;
	border: 1px solid #ddd;
	border-radius: 5px;
	font-size: 16px;
	padding: 40px 15px 20px;
	font-weight: 500;
	margin: 10px 0;
	color: #333;
}

.preview-box1 {
	position: relative;
	background: #fafafa;
	border: 1px solid #ddd;
	border-radius: 5px;
	font-size: 16px;
	padding: 40px 15px 20px;
	font-weight: 500;
	margin: 0 0;
	color: #333;
	max-height: 65vh;
	height: 65vh;
	float: left;
	overflow: auto;
	width: 65vh;
}
.preview-title {
	position: absolute;
	top: 0;
	left: 0;
	font-size: 10px;
	text-transform: uppercase;
	font-weight: 600;
	background: #eee;
	border: 1px solid #ddd;
	border-top: 0;
	border-left: 0;
	border-radius: 5px 0 5px 0;
	padding: 5px 12px;
	letter-spacing: 1px;
}
.copy-icon {
	background-size: 16px !important;
	padding: 15px;
	border: 2px solid rgba(255, 255, 255, 0.5);
	background-repeat: no-repeat;
	background-position: center;
	float: right;
	background-color: #ef2312;
	cursor: pointer;
}
@include media-breakpoint-down(lg) {
	// Styles

	.table-header-content {
		font-size: 9px;
	}
	.main-content {
		font-size: 12px;
	}
	.table-content {
		font-size: 12px;
	}
	.sub-content {
		font-size: 8px;
	}
	.dashboard-title {
		font-size: 18px;
	}
}
