* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	background: #ffffff;
}

section {
	width: 600px;
	margin: 0 auto;
	background: #604aa9;
	padding: 30px;
	border-radius: 4px;
	margin-top: 60px;
}

section form {
	width: 100%;
	display: flex;
}

section form input {
	flex: 1;
	height: 42px;
	border-radius: 4px;
	padding: 0 15px;
	font-size: 16px;
	color: #666;
	border: 0;
}

section form button {
	width: 100px;
	border: 0;
	border-radius: 4px;
	background: #a0e03a;
	font-weight: bold;
	color: #222;
	font-size: 16px;
	margin-left: 10px;
}

section ul {
	list-style: none;
	margin-top: 20px;
}

section ul li {
	line-height: 50px;
	margin: 0 20px;
	color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

section ul li s {
	opacity: 0.6;
}

section ul li + li {
	border-top: 1px solid rgba(255, 255, 255, 0.2);
}

section ul li button {
	border: 0;
	background: #fff;
	border-radius: 4px;
	padding: 6px 10px;
	font-weight: bold;
	font-size: 12px;
	margin-left: 10px;
}
